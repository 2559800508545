import React from "react"
import { CssBaseline } from "@material-ui/core"

import "hamburgers/dist/hamburgers.min.css"
import SelectedLanguageProvider from "./contexts/SelectedLanguage"
import ColorSchemeProvider from "./contexts/ColorScheme"
import ThemeProvider from "./ThemeProvider"

export const wrapPageElement = ({ element }) => {
  return (
    <SelectedLanguageProvider>
      <ColorSchemeProvider>
        <ThemeProvider>
          <CssBaseline />
          {element}
        </ThemeProvider>
      </ColorSchemeProvider>
    </SelectedLanguageProvider>
  )
}
