interface RelatedPosts {
  title: string
}

export interface TextForLanguage {
  relatedPosts: RelatedPosts
}

const englishText: TextForLanguage = {
  relatedPosts: {
    title: "Related Posts",
  },
}

const germanText: TextForLanguage = {
  relatedPosts: {
    title: "Ähnliche Beiträge",
  },
}

const postTextForLanguages = {
  english: englishText,
  german: germanText,
}

export default postTextForLanguages
