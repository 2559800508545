interface FormText {
  isRequiredError: string
}

interface FormTextForLanguages {
  english: FormText
  german: FormText
}

const formTextForLanguages: FormTextForLanguages = {
  english: {
    isRequiredError: "This value is required.",
  },
  german: {
    isRequiredError: "Dies ist ein Pflichtfeld.",
  },
}

export default formTextForLanguages
