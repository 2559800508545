import { LocalizedContent } from "../types/Localization"

type ItemInfo = LocalizedContent | string

export type GetLink = (item: ItemInfo) => string

const getLinkPrefixer = (prefix: string): GetLink => (itemInfo: ItemInfo) => {
  const slug: string = typeof itemInfo === "string" ? itemInfo : itemInfo.slug
  return `/${prefix}/${slug}`
}

export const getPostLink: GetLink = getLinkPrefixer("blog")

export const getCategoryLink: GetLink = getLinkPrefixer("blog/category")

export const getProductLink: GetLink = (product: ItemInfo): string => {
  if (typeof product === "string") {
    throw new Error("Received a product slug instead of LocalizedContent")
  }
  return `/${product.locale}/products/${product.slug}`
}

export const getIfIsNotProductsUrl = (pathname: string): boolean => {
  return pathname.search("products") === -1
}
