import React from "react"

interface TitleAndDescription {
  title: string
  description: string
}

interface Hero extends Omit<TitleAndDescription, "description"> {
  explanation: string
  seoTitle: string
  description: TitleAndDescription["description"] | React.ReactNode
}

export interface Feature extends TitleAndDescription {}

export type MoodInfo = TitleAndDescription

interface Moods {
  morning: MoodInfo
  day: MoodInfo
  evening: MoodInfo
  night: MoodInfo
}

interface PeaceAndRelaxation extends TitleAndDescription {
  beCalm: Feature
  reduceStress: Feature
  sleepBetter: Feature
  comeToRest: Feature
  moods: Moods
}

interface Testimonials {
  jessica: string
  tobias: string
  ilka: string
  michael: string
}

interface Team {
  title: string
}

interface ForKids extends TitleAndDescription {
  feature: TitleAndDescription
  buttonText: string
  form: Form
}

interface AlreadySubscribedError {
  linkText: string
  message: string
}

interface Form {
  error: string
  alreadySubscribedError: AlreadySubscribedError
  successMessage: string
}

interface HomeText {
  hero: Hero
  peaceAndRelaxation: PeaceAndRelaxation
  testimonials: Testimonials
  title: string
  team: Team
  forKids: ForKids
}

const englishText: HomeText = {
  title: "Take your time out",
  hero: {
    title: "Take your time out",
    description: (
      <>
        Your life is to short for being stressed.
        <br />
        There’s a world of silence and relaxation just waiting for you.
        <br />
        Join Outa now for free!
      </>
    ),
    explanation:
      "We are under pressure every day. Rushing from meeting to meeting, from project to project. Preferably, we want to manage everything at once and always be available in the meantime. Stress has become a constant companion during our daily life. Permanent stress makes you sick though. Allow yourself to take some time off.",
    seoTitle: "your #1 app to take a time out.",
  },
  peaceAndRelaxation: {
    moods: {
      morning: {
        title: "Morning",
        description: "Motivation & Gratitude",
      },
      day: {
        title: "Day",
        description: "Energy & Focus",
      },
      evening: {
        title: "Evening",
        description: "Calm & Relax",
      },
      night: {
        title: "Night",
        description: "Sleep & Rest",
      },
    },
    title: "Peace and relaxation for you",
    description:
      "Taking a time-out for as little as a few minutes a day will help you to reduce stress, to sleep better, and to become more relaxed in the long run.",
    beCalm: {
      title: "Be Calm",
      description:
        "Guided meditations help you to become more composed and relaxed.",
    },
    reduceStress: {
      title: "Reduce stress",
      description:
        "Practice tranquility and reduce your stress level with 10 minutes a day.",
    },
    sleepBetter: {
      title: "Sleep better",
      description: "Fall asleep faster and get a better night’s sleep.",
    },
    comeToRest: {
      title: "Come to rest",
      description: "With unguided time-outs at your own pace.",
    },
  },
  testimonials: {
    jessica:
      "The time-outs fuel me with new energy for work, everyday life, and exercising.",
    tobias: "With unguided time-outs at your own pace.",
    ilka:
      "To take a 10-minute time-out has become an integral part of my daily routine.",
    michael:
      "The breathing exercises help me switch off and calm down after a strenuous day.",
  },
  team: {
    title: "Team",
  },
  forKids: {
    title: "For Kids",
    description:
      "A loving meditation story for children. Get your free mindful adventure right into you inbox.",
    buttonText: "Free Download",
    feature: {
      title: "Little Big Bear",
      description:
        "Like heart and mind, the little Bear and the big Bear are not always in harmony when it comes to dealing with life's challenges. On an adventure, their friendship is put to the test, and they must learn that great goals can only be achieved together.",
    },
    form: {
      error: "Sorry, there was a problem. Please try again later.",
      alreadySubscribedError: {
        message: "is already subscribed to list Outa.app",
        linkText: "Click here to update your profile",
      },
      successMessage:
        "Please check your inbox. Your mindful adventure is just one click away.",
    },
  },
}

const germanText: HomeText = {
  title: "Nimm Dir Deine Auszeit",
  hero: {
    title: "Nimm Dir Deine Auszeit",
    description: (
      <>
        Dein Leben ist zu kurz um gestresst zu sein.
        <br />
        Eine Welt voller Ruhe und Entspannung wartet auf Dich.
        <br />
        Jetzt kostenlos herunterladen!
      </>
    ),
    explanation:
      "Jeden Tag stehen wir unter Strom. Hetzen von Termin zu Termin und von Projekt zu Projekt. Wir wollen am besten alles auf einmal erledigen und sind dabei auch noch überall und ständig erreichbar. Stress ist aus dem Alltag nicht mehr wegzudenken. Dauerhafter Stress macht Dich krank. Schalte den Lärm der Welt für ein paar Minuten ab und besinne Dich auf das Wesentliche.",
    seoTitle: "Deine #1 app für eine Auszeit.",
  },
  peaceAndRelaxation: {
    moods: {
      morning: {
        title: "Morgen",
        description: "Motivation & Dankbarkeit",
      },
      day: {
        title: "Tag",
        description: "Energie & Fokus",
      },
      evening: {
        title: "Abend",
        description: "Ruhe & Entspannung",
      },
      night: {
        title: "Nacht",
        description: "Schlaf & Erholung",
      },
    },
    title: "Ruhe und Entspannung für Dich",
    description:
      "Schon ein paar Minuten tägliche Auszeit, helfen Dir nachhaltig dabei Stress zu reduzieren, besser zu schlafen und gelassener zu werden.",
    beCalm: {
      title: "Werde Gelassen",
      description:
        "Geführte Meditationen verhelfen Dir zu mehr Gelassenheit und Entspanntheit.",
    },
    reduceStress: {
      title: "Reduziere Stress",
      description:
        "Übe Gelassenheit und reduziere Dein Stresslevel mit nur 10 Minuten täglich.",
    },
    sleepBetter: {
      title: "Schlafe Besser",
      description: "Schlafe schneller ein und verbessere Deine Nachtruhe.",
    },
    comeToRest: {
      title: "Komme zur Ruhe",
      description: "Mit ungeführten Auszeiten in Deinem ganz eigenen Tempo.",
    },
  },
  testimonials: {
    jessica:
      "Ich hätte nicht gedacht das ich mich so für die Stille begeistern kann.",
    tobias: "Die Auszeiten geben mir neue Energie für Beruf, Alltag und Sport.",
    ilka:
      "10 Minuten Auszeit zu nehmen ist mittlerweile ein festes Ritual in meinem Alltag.",
    michael:
      "Die Atemübungen helfen mir nach einem anstregenden Tag abzuschalten und zur Ruhe zu kommen.",
  },
  team: {
    title: "Team",
  },
  forKids: {
    title: "Für Kinder",
    description:
      "Eine liebevolle Meditationsgeschichte für Kinder. Wir schicken Dir Dein Buch kostenlos direkt in Dein Postfach.",
    buttonText: "Kostenlos Herunterladen",
    feature: {
      title: "Kleiner Großer Bär",
      description:
        "Wie Herz und Verstand sind sich die beiden Bären nicht immer ganz einig, wenn es darum geht mit den Herausforderungen des Lebens umzugehen. Auf ihrer abenteuerlichen Reise wird ihre Freundschaft auf die Probe gestellt und sie müssen lernen, dass sich große Ziele nur gemeinsam verwirklichen lassen.",
    },
    form: {
      error:
        "Entschuldigung, es gab ein Problem. Bitte versuchen Sie es später erneut.",
      alreadySubscribedError: {
        message: "wurde bereits registriert.",
        linkText: "Klicke hier um Deine Einstellungen zu bearbeiten",
      },
      successMessage:
        "Bitte prüfe Dein Postfach. Dein Buch ist nur einen Klick entfernt.",
    },
  },
}

const homeTextForLanguages = {
  english: englishText,
  german: germanText,
}

export default homeTextForLanguages
