import { navigate } from "gatsby"
import { useContext, useEffect } from "react"
import { useLocation } from "@reach/router"

import links from "../constants/links"
import { SelectedLanguageContext } from "../contexts/SelectedLanguage"
import { LocalizedContent } from "../types/Localization"
import { useItemForSelectedLanguage } from "./language"
import { GetLink } from "./links"
import { preferencesModule } from "./preferences"
import { useDefaultLocale } from "./localizedUrls"

export const useRedirectToPageWithSelectedLanguage = <
  LocalizedItem extends LocalizedContent
>(
  item: LocalizedItem,
  items: LocalizedItem[],
  getLink: GetLink
) => {
  const { language: selectedLanguage } = useContext(SelectedLanguageContext)!
  const itemForLanguage:
    | LocalizedItem
    | undefined = useItemForSelectedLanguage<LocalizedItem>(item, items)

  const redirectToLanguageContent = (itemForLanguage: LocalizedItem): void => {
    const link: string = getLink(itemForLanguage)
    navigate(link)
  }

  const checkIfLanguageContentExistsAndRedirectUser = (): void => {
    if (itemForLanguage) {
      return redirectToLanguageContent(itemForLanguage)
    }
    navigate(links.home)
  }

  const checkLanguageAndRedirectToLanguageContent = (): void => {
    if (!itemForLanguage || itemForLanguage.id !== item.id) {
      return checkIfLanguageContentExistsAndRedirectUser()
    }
  }

  useEffect(() => {
    checkLanguageAndRedirectToLanguageContent()
  }, [selectedLanguage])
}

export type Preference = string | undefined | null

export const getPreference = async (): Promise<Preference> => {
  if (window.indexedDB && preferencesModule) {
    return preferencesModule.preferences.get("lang")
  }
  return null
}

export const useRedirectToPageWithLocalePrefix = () => {
  const location = useLocation()
  const defaultLocale = useDefaultLocale()

  const redirectToLocale = async (): Promise<void> => {
    const preference: Preference = await getPreference()
    const locale: string = preference || defaultLocale
    const fullPath: string = `/${locale}${location.pathname}`
    navigate(fullPath)
  }

  type Links = string[]

  const getLinkWithoutTrailingSlash = (
    lastIndex: number,
    baseLink: string
  ): string => {
    if (baseLink === "/") {
      return ""
    }
    return baseLink.substring(0, lastIndex)
  }

  const addLinksFromLinkWithTrailingSlash = (
    lastIndex: number,
    baseLink: string,
    links: Links
  ): Links => {
    const withoutTrailingSlash: string = getLinkWithoutTrailingSlash(
      lastIndex,
      baseLink
    )
    return [...links, withoutTrailingSlash, baseLink]
  }

  const addLinksFromLinkWithoutTrailingSlash = (
    baseLink: string,
    links: Links
  ): Links => {
    const withTrailingSlash: string = `${baseLink}/`
    return [...links, withTrailingSlash, baseLink]
  }

  const addLink = (links: Links, baseLink: string): Links => {
    const lastIndex = baseLink.length - 1
    const lastCharacter: string = baseLink[lastIndex]
    const hasTrailingSlash: boolean = lastCharacter === "/"
    if (hasTrailingSlash) {
      return addLinksFromLinkWithTrailingSlash(lastIndex, baseLink, links)
    }
    return addLinksFromLinkWithoutTrailingSlash(baseLink, links)
  }

  const getLinksForLocalizedPages = (): Links => {
    const localizedPages: Links = [links.blog]
    return localizedPages.reduce(addLink, [])
  }

  const tryRedirectingToLocale = (): Promise<void> => {
    const localizedPages: Links = getLinksForLocalizedPages()
    const isLocalizedPage: boolean = localizedPages.includes(location.pathname)
    if (isLocalizedPage) {
      return redirectToLocale()
    }
  }

  useEffect(() => {
    tryRedirectingToLocale()
  }, [defaultLocale])
}
