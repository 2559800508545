import { useLocation } from "@reach/router"
import _ from "lodash/array"

import { DEFAULT_LOCALE } from "../constants/locales"
import Language from "../types/Language"
import { useLanguages } from "./language"

export const getUrlLocalePattern = (locale: string): RegExp =>
  new RegExp(`^\/(${locale})\/?`)

export const getAlternateLocalizedUrl = (
  href: string,
  oldLocale: string,
  newLocale: string
): string => {
  const localePattern: RegExp = new RegExp(`^\/${oldLocale}`)
  const localePath: string = `/${newLocale}`
  return href.replace(localePattern, localePath)
}

export const useDefaultLocale = (): string => {
  const languages = useLanguages()
  const location = useLocation()

  const addLocaleFromNavigatorLanguage = (
    locales: string[],
    localeOrLocaleWithLocation: string
  ): string[] => {
    const localeWithLocationMatch: RegExpMatchArray = localeOrLocaleWithLocation.match(
      /^(\w{2})-/
    )
    if (localeWithLocationMatch) {
      return [...locales, localeWithLocationMatch[1]]
    }
    return [...locales, localeOrLocaleWithLocation]
  }

  const getNavigatorLanguageLocales = (): string[] => {
    return navigator.languages.reduce(addLocaleFromNavigatorLanguage, [])
  }

  const getCodeFromLanguage = (language: Language): Language["code"] =>
    language.code

  const getLanguageCodes = (): Language["code"][] =>
    languages.map(getCodeFromLanguage)

  const getLocalizedUrlPattern = (): RegExp => {
    const languageCodes: Language["code"][] = getLanguageCodes()
    const languageCodePattern: string = languageCodes.join("|")
    return getUrlLocalePattern(languageCodePattern)
  }

  const getLocalizationFromUrl = (): null | Language["code"] => {
    const localizedUrlPattern: RegExp = getLocalizedUrlPattern()
    const localeMatch = location.pathname.match(localizedUrlPattern)
    return localeMatch ? localeMatch[1] : null
  }

  const getNavigatorLanguage = (): string | null => {
    if (typeof window === "undefined") return null
    const navigatorLocales: string[] = getNavigatorLanguageLocales()
    const languageCodes: Language["code"][] = getLanguageCodes()
    const supportedLocales: string[] = _.intersection(
      navigatorLocales,
      languageCodes
    )
    return supportedLocales.length ? supportedLocales[0] : null
  }

  const localizationFromUrl = getLocalizationFromUrl()
  const navigatorLanguage = getNavigatorLanguage()
  return localizationFromUrl || navigatorLanguage || DEFAULT_LOCALE
}
