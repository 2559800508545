import { email } from "../../constants/email"
import links from "../../constants/links"

export interface NavLink {
  href: string
  name: string
  openInNewTab?: boolean
}

export interface Section {
  name: string
  links: NavLink[]
}

export interface Contact {
  title: string
  message: string
  button: string
  error: string
}

export interface Footer {
  description: string
  sections: Section[]
  copyright: string
  contact: Contact
}

export interface Nav {
  links: NavLink[]
  download: string
}

interface CookieBanner {
  start: string
  privacyStatement: string
  end: string
  accept: string
  decline: string
}

interface Colors {
  morning: string
  day: string
  evening: string
  night: string
}

export interface LanguageText {
  nav: Nav
  footer: Footer
  readButtonText: string
  cookieBanner: CookieBanner
  colors: Colors
}

const socialLinks: NavLink[] = [
  {
    name: "Instagram",
    href: links.instagram,
    openInNewTab: true,
  },
  {
    name: "Facebook",
    href: links.facebook,
    openInNewTab: true,
  },
  {
    name: "YouTube",
    href: links.youtube,
    openInNewTab: true,
  },
]

const englishText: LanguageText = {
  nav: {
    download: "Download",
    links: [
      {
        name: "Weblog",
        href: links.blog,
      },
    ],
  },
  footer: {
    description: "When do you take some time out?",
    sections: [
      {
        name: "Outa",
        links: [
          {
            name: "Weblog",
            href: links.blog,
          },
          {
            name: "Imprint",
            href: links.impressum,
          },
          {
            name: "Privacy",
            href: links.privacy,
          },
          {
            name: "Terms",
            href: links.terms,
          },
          {
            name: "Team",
            href: links.team,
          },
        ],
      },
      {
        name: "Contact",
        links: [
          {
            name: email,
            href: links.email,
          },
          {
            name: "Message",
            href: links.message,
          },
        ],
      },
      {
        name: "Social",
        links: socialLinks,
      },
    ],
    copyright: "by Outa.app",
    contact: {
      title: "Contact Us",
      message: "Message",
      button: "Send",
      error:
        "Sorry, there was a problem. Please try contacting us through email at",
    },
  },
  readButtonText: "Read",
  cookieBanner: {
    start:
      "This site uses cookies to improve your experience. Please refer to the",
    privacyStatement: "Privacy Statement",
    end: "for details.",
    accept: "Accept",
    decline: "Decline",
  },
  colors: {
    morning: "Morning",
    day: "Day",
    evening: "Evening",
    night: "Night",
  },
}

const germanText: LanguageText = {
  nav: {
    download: "Herunterladen",
    links: [
      {
        name: "Weblog",
        href: links.blog,
      },
    ],
  },
  footer: {
    description: "Wann nimmst Du Dir eine Auszeit?",
    sections: [
      {
        name: "Outa",
        links: [
          {
            name: "Weblog",
            href: links.blog,
          },
          {
            name: "Impressum",
            href: links.impressum,
          },
          {
            name: "Rechtliches",
            href: links.privacy,
          },
          {
            name: "Terms",
            href: links.privacy,
          },
          {
            name: "Mannschaft",
            href: links.team,
          },
        ],
      },
      {
        name: "Kontakt",
        links: [
          {
            name: email,
            href: links.email,
          },
          {
            name: "Nachricht",
            href: links.message,
          },
        ],
      },
      {
        name: "Soziale Netze",
        links: socialLinks,
      },
    ],
    copyright: "Outa.app",
    contact: {
      title: "Kontakt",
      message: "Nachricht",
      button: "Absenden",
      error:
        "Entschuldigung, es gab ein Problem. Bitte kontaktieren Sie uns per E-Mail unter",
    },
  },
  readButtonText: "Lesen",
  cookieBanner: {
    start:
      "Wir verwenden Cookies auf unsere Webseite, um Deinen Besuch effizienter zu machen und Dir mehr Benutzerfreundlichkeit zu bieten. Weitere Details findest du in",
    privacyStatement: "unserer Datenschutzerklärung",
    end: "",
    accept: "Akzeptieren",
    decline: "Abfall",
  },
  colors: {
    morning: "Morgen",
    day: "Tag",
    evening: "Abend",
    night: "Nacht",
  },
}

export const languagesAndText = {
  english: englishText,
  german: germanText,
}
