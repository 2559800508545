interface ReadByCategory {
  title: string
}

export interface TextForLanguage {
  readByCategory: ReadByCategory
}

const englishText: TextForLanguage = {
  readByCategory: {
    title: "Read by category",
  },
}

const germanText: TextForLanguage = {
  readByCategory: {
    title: "Filter nach Kategorie",
  },
}

const textForLanguages = {
  english: englishText,
  german: germanText,
}

export default textForLanguages
