import { NodeWithId } from "../types/Nodes"

const getIfNodeHasId = <Node extends NodeWithId>(id: NodeWithId["id"]) => (
  node: Node
): boolean => {
  return id === node.id
}

export const getNodeByIdFinder = <Node extends NodeWithId>() => (
  id: NodeWithId["id"],
  nodes: Node[]
): undefined | Node => {
  return nodes.find(getIfNodeHasId<Node>(id))
}
