import React, { useState, createContext, useEffect } from "react"

import { ProviderProps } from "./types"

export type ColorScheme = "gray" | "morning" | "day" | "evening" | "night"

type SetColorScheme = (colorScheme: ColorScheme) => void

export interface ContextValue {
  colorScheme: ColorScheme
  setColorScheme: SetColorScheme
}

type InitialContextValue = null | ContextValue

export const ColorSchemeContext = createContext<InitialContextValue>(null)

export default function ColorSchemeProvider({ children }: ProviderProps) {
  const getDefaultColorScheme = (): ColorScheme => {
    const hour: number = new Date().getHours()

    const times = {
      dusk: 6,
      noon: 12,
      evening: 18,
      night: 20,
    }

    if (hour >= times.night || hour < times.dusk) {
      return "night"
    } else if (hour < times.noon) {
      return "morning"
    } else if (hour < times.evening) {
      return "day"
    }
    return "evening"
  }

  const [colorScheme, setColorScheme] = useState<ColorScheme>("gray")

  useEffect(() => {
    const browserDefaultColorScheme: ColorScheme = getDefaultColorScheme()
    setColorScheme(browserDefaultColorScheme)
  }, [])

  return (
    <ColorSchemeContext.Provider value={{ colorScheme, setColorScheme }}>
      {children}
    </ColorSchemeContext.Provider>
  )
}
