import { email } from "./email"

const links = {
  appStore: "https://apps.apple.com/de/app/outa-meditation-schlaf/id1534938637",
  blog: "/blog/",
  home: "/",
  mission: "/mission/",
  impressum: "/legal-notice/",
  privacy: "/privacy-policy/",
  terms: "/terms/",
  email: `mailto:${email}`,
  message: "https://wa.me/+6588908896",
  instagram: "https://www.instagram.com/outaapp/",
  facebook: "https://www.facebook.com/outaapp",
  youtube: "https://www.youtube.com/channel/UCAhXIbPYG_db2Y_DvYVBftg",
  download: "/download",
  team: "/team",
}

export default links
