// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-category-strapi-tags-slug-tsx": () => import("./../../../src/pages/blog/category/{StrapiTags.slug}.tsx" /* webpackChunkName: "component---src-pages-blog-category-strapi-tags-slug-tsx" */),
  "component---src-pages-blog-strapi-language-code-tsx": () => import("./../../../src/pages/blog/{StrapiLanguage.code}.tsx" /* webpackChunkName: "component---src-pages-blog-strapi-language-code-tsx" */),
  "component---src-pages-blog-strapi-weblogs-slug-tsx": () => import("./../../../src/pages/blog/{StrapiWeblogs.slug}.tsx" /* webpackChunkName: "component---src-pages-blog-strapi-weblogs-slug-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-notice-tsx": () => import("./../../../src/pages/legal-notice.tsx" /* webpackChunkName: "component---src-pages-legal-notice-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-strapi-language-code-blog-tsx": () => import("./../../../src/pages/{StrapiLanguage.code}/blog.tsx" /* webpackChunkName: "component---src-pages-strapi-language-code-blog-tsx" */),
  "component---src-pages-strapi-language-code-tsx": () => import("./../../../src/pages/{StrapiLanguage.code}.tsx" /* webpackChunkName: "component---src-pages-strapi-language-code-tsx" */),
  "component---src-pages-strapi-product-locale-products-strapi-product-slug-tsx": () => import("./../../../src/pages/{StrapiProduct.locale}/products/{StrapiProduct.slug}.tsx" /* webpackChunkName: "component---src-pages-strapi-product-locale-products-strapi-product-slug-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

