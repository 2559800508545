import { graphql } from "gatsby"
import { useStaticQuery } from "gatsby"
import { useContext } from "react"

import blogOverview from "../content/pages/blogOverview"
import { languagesAndText } from "../content/pages/global"
import homeTextForLanguages from "../content/pages/home"
import postTextForLanguages from "../content/pages/post"
import {
  ContextValue,
  SelectedLanguageContext,
} from "../contexts/SelectedLanguage"
import { LanguageData, NodeWithId } from "../types/Nodes"
import { PostPreview } from "../types/Post"
import StrapiLanguage from "../types/Language"
import { QueryWithNodes } from "../types/queries"
import { getNodeByIdFinder } from "./find"
import Localization, {
  LocalizedItem,
  LocalizedItemWithLocalizations,
} from "../types/Localization"
import { Language } from "../types/languageNames"
import formTextForLanguages from "../content/pages/form"

interface AllStrapiLanguage extends QueryWithNodes<StrapiLanguage> {}

interface AllStrapiLanguageQuery {
  allStrapiLanguage: AllStrapiLanguage
}

export const useLanguages = (): StrapiLanguage[] => {
  const {
    allStrapiLanguage: { nodes },
  }: AllStrapiLanguageQuery = useStaticQuery(graphql`
    query Languages {
      allStrapiLanguage {
        nodes {
          id: strapiId
          name
          abbreviation
          code
        }
      }
    }
  `)
  return nodes
}

export const findLanguageById = getNodeByIdFinder<StrapiLanguage>()

export const useFindLanguageById = (
  id: NodeWithId["id"]
): StrapiLanguage | undefined => {
  const languages: StrapiLanguage[] = useLanguages()
  return findLanguageById(id, languages)
}

function throwMissingLanguageError(language: number) {
  throw new Error(`Text does not have data for language ${language}`)
}

export const useLanguageText = <LanguageTexts extends unknown>(
  texts: LanguageTexts
): LanguageTexts[keyof LanguageTexts] => {
  const { language } = useContext(SelectedLanguageContext)!
  const strapiLanguageData = useFindLanguageById(language)
  if (!strapiLanguageData) throwMissingLanguageError(language)
  const languageName: Language =
    strapiLanguageData.id === 1 ? "english" : "german"
  return texts[languageName]
}

export const useGlobalLanguageText = () => {
  return useLanguageText<typeof languagesAndText>(languagesAndText)
}

export const useHomeLanguageText = () => {
  return useLanguageText<typeof homeTextForLanguages>(homeTextForLanguages)
}

export const useBlogOverviewLanguageText = () => {
  return useLanguageText<typeof blogOverview>(blogOverview)
}

export const usePostLanguageText = () => {
  return useLanguageText<typeof postTextForLanguages>(postTextForLanguages)
}

export const useFormLanguageText = () => {
  return useLanguageText<typeof formTextForLanguages>(formTextForLanguages)
}

const getIfLanguageDataHasLanguage = <ItemLanguageData extends LanguageData>(
  language: ContextValue["language"]
) => (languageData: ItemLanguageData): boolean => {
  return languageData.language === language
}

export const findLanguageDataByLanguage = <
  ItemLanguageData extends LanguageData
>(
  language: ContextValue["language"],
  languages: ItemLanguageData[]
): undefined | ItemLanguageData => {
  return languages.find(getIfLanguageDataHasLanguage(language))
}

export const getLanguageDataGetter = <
  ItemLanguageData extends LanguageData
>() => (languages: ItemLanguageData[]): undefined | ItemLanguageData => {
  const { language } = useContext(SelectedLanguageContext)!
  return findLanguageDataByLanguage<ItemLanguageData>(language, languages)
}

const getIfLocalizationHasLocale = (locale: Localization["locale"]) => (
  localization: Localization
): boolean => {
  return localization.locale === locale
}

const useSelectedLanguageCode = (): StrapiLanguage["code"] => {
  const { language } = useContext(SelectedLanguageContext)!
  const selectedLanguage = useFindLanguageById(language)
  if (!selectedLanguage) throwMissingLanguageError(language)
  return selectedLanguage.code
}

export const useLocalizationForSelectedLanguage = (
  localizations: Localization[]
): Localization | undefined => {
  const code: StrapiLanguage["code"] = useSelectedLanguageCode()
  return localizations.find(getIfLocalizationHasLocale(code))
}

const getAllLocalizations = <Item extends LocalizedItemWithLocalizations>(
  item: Item
): Localization[] => {
  const defaultLocalization: Localization = {
    id: item.id,
    locale: item.locale,
  }
  return [...item.localizations, defaultLocalization]
}

const findItemWithLocalization = <Item extends LocalizedItemWithLocalizations>(
  languageLocalization: Localization,
  items: Item[]
): undefined | Item => {
  return getNodeByIdFinder<Item>()(languageLocalization.id, items)
}

export const useItemForSelectedLanguage = <
  Item extends LocalizedItemWithLocalizations
>(
  item: Item,
  items: Item[]
): Item | undefined => {
  const localizations: Localization[] = getAllLocalizations<Item>(item)
  const languageLocalization:
    | Localization
    | undefined = useLocalizationForSelectedLanguage(localizations)
  if (languageLocalization) {
    return findItemWithLocalization(languageLocalization, items)
  }
  return undefined
}

const getIfItemHasLocale = <Item extends LocalizedItem>(locale: string) => (
  item: Item
): boolean => {
  return item.locale === locale
}

const filterItemsByLocalization = <Item extends LocalizedItem>(
  localization: string,
  items: Item[]
): Item[] => {
  return items.filter(getIfItemHasLocale<Item>(localization))
}

export const useItemsWithSelectedLanguage = <Item extends LocalizedItem>(
  items: Item[]
): Item[] => {
  const code: StrapiLanguage["code"] = useSelectedLanguageCode()
  return filterItemsByLocalization<Item>(code, items)
}

export const usePostsForLanguage = (posts: PostPreview[]): PostPreview[] =>
  useItemsWithSelectedLanguage<PostPreview>(posts)
