import React, { useContext } from "react"
import { Theme, ThemeProvider as BaseThemeProvider } from "@material-ui/core"

import { ColorSchemeContext } from "./contexts/ColorScheme"
import baseTheme from "./theme"

interface Props {
  children: React.ReactNode
}

const ThemeProvider = ({ children }: Props) => {
  const { colorScheme } = useContext(ColorSchemeContext)!

  const getBackground = (): string => {
    switch (colorScheme) {
      case "morning":
        return "transparent linear-gradient(180deg, #D2D6E2 0%, #FBC6B1 15%, #FDDDD1 35%, #FFF4F1 70%, #FFF4F1 100%) 0% 0% no-repeat padding-box;"
      case "day":
        return "transparent linear-gradient(180deg, #4885C5 0%, #7CAEB9 15%, #C4E0ED 35%, #E8F3F7 70%, #E8F3F7 100%) 0% 0% no-repeat padding-box;"
      case "evening":
        return "transparent linear-gradient(180deg, #662062 0%, #ad3b68 15%, #bd4c6c 35%, #d16777 70%, #e36b7d 100%) 0% 0% no-repeat padding-box;"
      default:
        return "transparent linear-gradient(180deg, #081518 0%, #113E45 15%, #2B7E89 35%, #5093A3 70%, #86A8BB 100%) 0% 0% no-repeat padding-box;"
    }
  }

  const getTextColor = (): string => {
    switch (colorScheme) {
      case "morning":
        return "#48494B"
      case "day":
        return "#14496C"
      case "evening":
        return "#FFF8F8"
      default:
        return "#F0F0F0"
    }
  }

  const getTheme = (): Theme => {
    const { overrides, palette } = baseTheme
    const MuiCSSBaseline = overrides.MuiCssBaseline
    const { ["@global"]: global } = MuiCSSBaseline
    const { body } = global

    const textColor = getTextColor()
    const grayFilter = colorScheme === "gray" ? "grayscale(100%)" : "none"

    return {
      ...baseTheme,
      overrides: {
        ...overrides,
        MuiCssBaseline: {
          ...MuiCSSBaseline,
          "@global": {
            ...global,
            body: {
              ...body,
              background: getBackground(),
              backgroundSize: "cover",
              backdropFilter: grayFilter,
              filter: grayFilter,
            },
          },
        },
      },
      palette: {
        ...palette,
        text: {
          ...palette.text,
          primary: textColor,
        },
        divider: textColor,
      },
    }
  }

  return <BaseThemeProvider theme={getTheme()}>{children}</BaseThemeProvider>
}

export default ThemeProvider
