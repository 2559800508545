import { createTheme } from "@material-ui/core"

declare module "@material-ui/core/styles/createBreakpoints" {
  interface BreakpointOverrides {
    xs: true
    sm: true
    tabletLg: true
    md: true
    lg: true
    tabletXl: true
    xl: true
  }
}

const primaryColor = "#244869"
const fontFamily = "Rubik, sans-serif"
const defaultFontWeight = 300

const theme = createTheme({
  typography: {
    fontFamily: fontFamily,
    button: {
      textTransform: "none",
      fontWeight: defaultFontWeight,
    },
  },
  palette: {
    text: {
      primary: primaryColor,
    },
    divider: primaryColor,
  },
  overrides: {
    MuiButton: {
      root: {
        minWidth: 0,
        boxShadow: "none",
      },
      contained: {
        "&, &:hover": {
          boxShadow: "none",
        },
      },
      containedPrimary: {
        background: "linear-gradient(45deg, #74D2E1 0%, #5E93FE 100%)",
        color: "white",
        minWidth: "12.875rem",
        height: "3.3125rem",
        fontSize: "1.25rem",
        borderRadius: "0.7rem",
      },
    },
    MuiCssBaseline: {
      "@global": {
        html: {
          scrollBehavior: "smooth",
        },
        body: {
          fontFamily,
          fontWeight: defaultFontWeight,
        },
        a: {
          color: "#B9A1FF",
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 428,
      tabletLg: 768,
      md: 960,
      tabletXl: 1194,
      lg: 1280,
      xl: 1920,
    },
  },
})

theme.typography.h1 = {
  ...theme.typography.h1,
  fontSize: "2.5rem",
}

theme.typography.h2 = {
  ...theme.typography.h2,
  fontSize: "2rem",
  lineHeight: "2.8125rem",
}

theme.typography.h3 = {
  ...theme.typography.h3,
  fontSize: "1.625rem",
  lineHeight: "2.9375rem",
  fontWeight: 300,
}

theme.typography.body1 = {
  ...theme.typography.body1,
  fontWeight: 300,
  fontSize: "1.25rem",
  lineHeight: "2rem",
}

export default theme
