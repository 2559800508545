import React, { useState, createContext } from "react"
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"
import _ from "lodash"

import Language from "../types/Language"
import { ProviderProps } from "./types"
import { findLanguageById, useLanguages } from "../utils/language"
import { useEffect } from "react"
import { preferencesModule } from "../utils/preferences"
import { getPreference, Preference } from "../utils/redirect"
import {
  getAlternateLocalizedUrl,
  getUrlLocalePattern,
  useDefaultLocale,
} from "../utils/localizedUrls"
import { getIfIsNotProductsUrl } from "../utils/links"

type SetLanguage = (language: Language["id"]) => void

export interface ContextValue {
  language: Language["id"]
  setLanguage: SetLanguage
}

type InitialContextValue = null | ContextValue

export const SelectedLanguageContext = createContext<InitialContextValue>(null)

export default function SelectedLanguageProvider({ children }: ProviderProps) {
  const location = useLocation()
  const languages: Language[] = useLanguages()

  const getCodeFromLanguage = (language: Language): Language["code"] =>
    language.code

  const getLanguageCodes = (): Language["code"][] =>
    languages.map(getCodeFromLanguage)

  const getLocalizedUrlPattern = (): RegExp => {
    const languageCodes: Language["code"][] = getLanguageCodes()
    const languageCodePattern: string = languageCodes.join("|")
    return getUrlLocalePattern(languageCodePattern)
  }

  const getLocalizationFromUrl = (): null | Language["code"] => {
    const localizedUrlPattern: RegExp = getLocalizedUrlPattern()
    const localeMatch = location.pathname.match(localizedUrlPattern)
    return localeMatch ? localeMatch[1] : null
  }

  const getIfLanguageHasCode = (code: Language["code"]) => (
    language: Language
  ): boolean => {
    return code === language.code
  }

  const findLanguageByCode = (code: Language["code"]): Language => {
    return languages.find(getIfLanguageHasCode(code))
  }

  const DEFAULT_LANGUAGE_ID: Language["id"] = 1

  const getIdOfUrlLocalization = (
    languageFromUrl: Language["code"]
  ): Language["id"] => {
    const language: Language | undefined = findLanguageByCode(languageFromUrl)
    return language ? language.id : DEFAULT_LANGUAGE_ID
  }

  const getInitialLanguageId = (): Language["id"] => {
    const languageFromUrl: Language["code"] = getLocalizationFromUrl()
    if (!languageFromUrl) return DEFAULT_LANGUAGE_ID
    return getIdOfUrlLocalization(languageFromUrl)
  }

  const initialLanguageId: Language["id"] = getInitialLanguageId()

  const [language, setLanguage] = useState<Language["id"]>(initialLanguageId)
  const defaultLocale = useDefaultLocale()

  const trySettingPreference = (languageCode: Language["code"]) => {
    if (window.indexedDB && preferencesModule) {
      preferencesModule.preferences.set("lang", languageCode)
    }
  }

  const findLanguageByCodeAndSetLanguage = (preference: string): void => {
    const foundLanguage: Language | undefined = findLanguageByCode(preference)
    if (foundLanguage && language !== foundLanguage.id) {
      return setLanguage(foundLanguage.id)
    }
  }

  const trySettingDefaultPreference = (): void => {
    trySettingPreference(defaultLocale)
    findLanguageByCodeAndSetLanguage(defaultLocale)
  }

  const setLanguageFromPreferenceOrSetDefault = async () => {
    const preference: Preference = await getPreference()
    if (preference) {
      return findLanguageByCodeAndSetLanguage(preference)
    }
    return trySettingDefaultPreference()
  }

  const redirectFromLocalizedPageToAnother = (
    locale: Language["code"],
    newLanguage: Language
  ): void => {
    const urlWithNewLocale: string = getAlternateLocalizedUrl(
      location.pathname,
      locale,
      newLanguage.code
    )
    navigate(urlWithNewLocale)
  }

  const tryRedirectingToLocalizedPage = (newLanguage: Language): void => {
    const locale: null | Language["code"] = getLocalizationFromUrl()
    if (!locale) return
    const isNotProductsUrl: boolean = getIfIsNotProductsUrl(location.pathname)
    if (isNotProductsUrl && locale !== newLanguage.code) {
      return redirectFromLocalizedPageToAnother(locale, newLanguage)
    }
  }

  const updateLanguageAndPreference = (id: Language["id"]) => {
    const strapiLanguage: Language | undefined = findLanguageById(id, languages)
    if (strapiLanguage) {
      trySettingPreference(strapiLanguage.code)
      tryRedirectingToLocalizedPage(strapiLanguage)
    }
    setLanguage(id)
  }

  useEffect(() => {
    setLanguageFromPreferenceOrSetDefault()
  }, [defaultLocale, language])

  return (
    <SelectedLanguageContext.Provider
      value={{ language, setLanguage: updateLanguageAndPreference }}
    >
      {children}
    </SelectedLanguageContext.Provider>
  )
}
